.table-left-top-call {

    position: absolute;
    z-index: 5;
}

.table-left-top-call tr td img {
    width: 200px;

}

.td-top-1 {
    height: 40px;
}

.td-top-5 {
    width: 50px;
}

.bt {

    height: 25px;
    text-align: center;
}

.num-top,
.num-left {
    border: solid 1px #cccccc;
    border-radius: 2px;
}

.num-left {
    transform: rotate(-90deg);
    margin-left: 0px;
    margin-top: 100px;
    height: 30px;
    width: 60px;
}

.num-top {
    margin-top: -100px;

}

.num-right-container {
    margin-top: 100px;
}

.num-right {
    transform: rotate(-90deg);
    margin-top: 40px;

}

.num-right-input {
    transform: rotate(-90deg);
    
    width: 200px;
}

.num-bottom-input {
    width: 230px;
    margin-top: 300px;
}

.in {
    width: 100%;
    height: 25px;
    border: solid 1px #cccccc;
    border-radius: 2px;
    text-align: center;
}

.table-right tr td {
    width: 50px
}

.table-right td img {
    height: 50px;
}

#img-tr {
    padding-left: 5px;
}

.win {

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: green;
    padding-top: 50px;
    display: none;
}

.win span {
    color: black;
    font-weight: initial;
}

.rezultCall {

    height: auto;
    border-bottom: solid 2px green;
}

.test {
    padding-top: 20px;
    color: green;
    font-size: 30pt;
}

.col-right-call {
    flex: content;
}

.form-modal-p {
    display: none;
}

.fw-700 {
    font-weight: 700;
}
.box-left{
   
    display:inherit;

}
.col-left{
    margin-top: 100px;
}
.result{
    margin-top: 100px;
}
.form-control:focus {
    border-color: green !important;
    box-shadow: 0 1px 1px green inset, 0 0 4px green !important;
}
/* range */




.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px rgb(240, 235, 235), 0 0 0 .25rem rgba(15, 138, 42, 0.25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px rgb(241, 239, 239), 0 0 0 .25rem rgba(36, 116, 16, 0.25)
}


.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #1aa31a;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}




.form-range::-webkit-slider-thumb:active {
    background-color: #064716
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #18912c;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}


.form-range::-moz-range-thumb:active {
    background-color: #8a8a8a
}


  /*range*/
.main-image-box {}

.w-100px{
    width: 100px;
}
.pt-320{
    margin-top: 320px;
}
@media screen and (max-width: 1000px) {
    .col-right-call {
        margin-top: 240px;
        flex: auto;
    }
    .result{
        margin-top: 10px;
    }

}